import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';
import { NewsRoomTemplate } from '../templates/newsroom.template';

import { NewsroomQueryQuery } from '../../graphql-types';
import { mapNewsroomQueryToNewsroomProps } from '../data-mappers/newsroom.mapper';
import SEO from '../components/seo';
import { AvailableLocales, parseLocale } from '../locale';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export type TranslationData = {
  locale: AvailableLocales;
  [K: string]: string;
};

export const query = graphql`
  fragment NewsroomContent on cms_Newsroom {
    metas {
      id
      description
    }
    title
    showContentTags
    showHighlightedContent
    showCategoryDescription
    highlighted_news_article {
      ...ArticleWithThumbnail
    }
  }

  fragment Tag on cms_Tag {
    name
  }

  fragment NewsCategory on cms_ContentCategory {
    id
    title
    slug
  }

  fragment NewsCategoryWithHighlightedArticle on cms_ContentCategory {
    id
    title
    slug
    highlighted_article {
      ...ArticleWithThumbnail
    }
  }

  fragment NewsCategoryWithArticlesId on cms_ContentCategory {
    ...NewsCategoryWithHighlightedArticle
    articles {
      id
    }
  }

  fragment PaginatedCollectionPage on PaginatedCollectionPage {
    nodes
    hasNextPage
    nextPage {
      id
    }
    collection {
      id
    }
  }

  fragment PaginatedCollection on PaginatedCollection {
    id
    name
    pages {
      id
      nodes
      hasNextPage
      nextPage {
        id
      }
    }
  }

  query NewsroomQuery($language: String, $paginatedCollectionName: String) {
    cms {
      newsroom(locale: $language) {
        ...NewsroomContent
      }
      contentCategories(locale: $language, sort: "created_at:desc") {
        ...NewsCategory
        articlesCount
      }
    }

    paginatedCollectionPage(collection: { name: { eq: $paginatedCollectionName } }) {
      ...PaginatedCollectionPage
    }
  }
`;

const NewsroomPage: React.FC<{ data: NewsroomQueryQuery }> = ({ data }) => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'newsroom' },
      { locale: 'fr', slug: 'articles' },
    ],
  });

  const translation: TranslationData = {
    locale: locale,
    load_more: intl.formatMessage({ id: 'load_more' }),
    read_more: intl.formatMessage({ id: 'read_more' }),
    go_to_news: intl.formatMessage({ id: 'go_to_news' }),
  };

  if (!data?.cms?.newsroom) return null;

  const { cms } = data;

  const newsroomProps = mapNewsroomQueryToNewsroomProps(data, translation);

  return (
    <>
      <SEO title={cms.newsroom?.title ?? ''} description={cms.newsroom?.metas?.description ?? ''} lang={locale} />

      {newsroomProps && <NewsRoomTemplate {...newsroomProps} />}
    </>
  );
};

export default NewsroomPage;
