import { NewsRoomProps } from '../templates/newsroom.template';

import { EditorialContentCategory, EditorialContentMenuProps } from '../components/molecules/editorial-content-menu';
import { EditorialContentListProps } from '../components/molecules/editorial-content-list';
import { HighlightedEditorialContentProps } from '../components/molecules/highlighted-editorial-content';
import {
  ArticleWithThumbnailFragment,
  NewsCategoryWithArticlesIdFragment,
  NewsroomContentFragment,
  NewsroomQueryQuery,
  NewsroomByCategoryQuery,
} from '../../graphql-types';
import { getPaginationData } from './common.mapper';
import { isPresent, safeMap } from './utils';
import appConfig from '../../app.config.json';

import { TranslationData } from '../pages/newsroom';
import { mapEditorialContentToEditorialThumbnailProps } from './editorial-content.mapper';
import { mapFragmentToImageProps } from './image.mapper';

export function mapNewsroomQueryToNewsroomProps(
  content: NewsroomQueryQuery,
  translation: TranslationData
): NewsRoomProps | undefined {
  const {
    cms: { contentCategories, newsroom },
    paginatedCollectionPage,
  } = content;

  if (!paginatedCollectionPage || !contentCategories || !newsroom) {
    return undefined;
  }

  const cleanArticles = paginatedCollectionPage.nodes.filter(isPresent);

  const paginationData = getPaginationData(paginatedCollectionPage);
  const highlightedArticle = newsroom.showHighlightedContent
    ? newsroom?.highlighted_news_article || cleanArticles?.[0]
    : undefined;

  const cleanContentCategories = contentCategories.filter(isPresent);
  const totalArticlesCount = cleanContentCategories.reduce((acc, curr) => curr.articlesCount + acc, 0);

  return {
    title: newsroom?.title ?? '',
    newsContainer: {
      menu: mapContentCategoriesToEditorialMenuProps(
        cleanContentCategories,
        translation,
        totalArticlesCount,
        newsroom.showCategoryDescription ?? false
      ),
      editorialContentList: mapEditorialContentToEditorialContentListProps(newsroom, cleanArticles, translation),
      showContentTags: newsroom?.showContentTags ?? false,
      showHighlightContent: newsroom?.showHighlightedContent ?? false,
      type: 'news',
      paginationData,
      highlightedContent:
        highlightedArticle &&
        mapHighlightedContentFragmentToHighlightedEditorialContentProps(highlightedArticle, translation),
    },
  };
}

export function mapNewsroomByCategoryQueryToNewsroomProps(
  content: NewsroomByCategoryQuery['cms'],
  translation: TranslationData,
  activeCategoryId: string
): NewsRoomProps | undefined {
  const { newsroom, contentCategories, contentCategory } = content;

  if (!contentCategory || !contentCategories || !newsroom) return undefined;

  const showContentTags = newsroom.showContentTags ?? false;

  const highlightedArticle = contentCategory
    ? contentCategory?.highlighted_article || contentCategory?.articles?.[0]
    : undefined;
  const categoryArticles = contentCategory?.articles
    ?.filter(isPresent)
    ?.filter(article => article.id !== highlightedArticle?.id);

  const firstPageArticles = categoryArticles ? categoryArticles.slice(0, appConfig.ITEMS_PER_PAGE) : [];
  const allArticlesContent = safeMap(
    article => mapEditorialContentToEditorialThumbnailProps(article, showContentTags, 'news', translation.locale),
    categoryArticles
  );

  const articlesCategories = contentCategories.filter(isPresent);
  const totalArticlesCount = contentCategories.reduce((acc, category) => (category?.articlesCount || 0) + acc, 0);

  return {
    title: newsroom.title ?? '',
    newsContainer: {
      menu: mapContentCategoriesToEditorialMenuProps(
        articlesCategories,
        translation,
        totalArticlesCount,
        newsroom?.showCategoryDescription ?? false,
        activeCategoryId
      ),
      editorialContentList: mapEditorialContentToEditorialContentListProps(newsroom, firstPageArticles, translation),
      activeCategoryId: activeCategoryId,
      showContentTags,
      showHighlightContent: newsroom.showHighlightedContent ?? false,
      type: 'news',
      itemsForCategory: allArticlesContent,
    },
  };
}

function mapHighlightedContentFragmentToHighlightedEditorialContentProps(
  content: ArticleWithThumbnailFragment,
  translation: TranslationData
): HighlightedEditorialContentProps {
  return {
    title: content.title ?? '',
    text: content.excerpt ?? '',
    image: mapFragmentToImageProps(content.thumbnail, translation.locale),
    cta: {
      text: translation['read_more'],
      title: translation['go_to_news'],
      size: 'medium',
      icon: 'right-arrow',
      link: {
        type: 'news',
        slug: content.slug ?? '',
      },
    },
    tags: safeMap(tag => tag.name, content.tags),
  };
}

function mapContentCategoryToEditorialContentCategoryProps(
  content: NewsCategoryWithArticlesIdFragment,
  translation: TranslationData
): EditorialContentCategory {
  const article = content.highlighted_article || content.articles?.[0];
  const highlight = article
    ? mapHighlightedContentFragmentToHighlightedEditorialContentProps(article, translation)
    : undefined;

  return {
    id: content.id,
    name: content.title ?? '',
    count: content.articles?.length || 0,
    slug: content.slug ?? '',
    ...(highlight && { highlighted: highlight }),
  };
}

export function mapContentCategoriesToEditorialMenuProps(
  content: NewsCategoryWithArticlesIdFragment[],
  translation: TranslationData,
  totalItemsCount: number,
  showCategoryDescription: boolean,
  activeCategoryId?: string
): EditorialContentMenuProps {
  const categories = content.map(categ => mapContentCategoryToEditorialContentCategoryProps(categ, translation));
  const activeCategory = categories.find(categ => categ.id === activeCategoryId);

  return {
    categories,
    activeCategory,
    totalItemsCount,
    type: 'news',
    showCategoryDescription,
  };
}

function mapEditorialContentToEditorialContentListProps(
  content: NewsroomContentFragment,
  articles: ArticleWithThumbnailFragment[],
  translation: TranslationData
): EditorialContentListProps {
  const showTags = content.showContentTags ?? false;

  return {
    thumbnails: articles.map(article =>
      mapEditorialContentToEditorialThumbnailProps(article, showTags, 'news', translation.locale)
    ),
    cta: {
      text: translation['load_more'],
      title: translation['go_to_news'],
      size: 'medium',
      icon: 'right-arrow',
    },
    showContentTags: showTags,
  };
}
