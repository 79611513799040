import React, { useState } from 'react';
import { Heading } from '../components/atoms/heading';
import {
  FilteredEditorialContentContainer,
  FilteredEditorialContentContainerProps,
} from '../components/molecules/filtered-editorial-content-container';

import { HeaderSection } from '../components/molecules/header-section';

export type NewsRoomProps = {
  newsContainer: FilteredEditorialContentContainerProps;
  title: string;
};

export const NewsRoomTemplate: React.FC<NewsRoomProps> = props => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const menuHandler = (showMenu: boolean) => {
    setIsMenuOpen(showMenu);
  };

  return (
    <>
      {!isMenuOpen && (
        <HeaderSection>
          <Heading level="h1" noMargin text={props.title} />
        </HeaderSection>
      )}

      <FilteredEditorialContentContainer {...props.newsContainer} isMenuOpen={isMenuOpen} setIsMenuOpen={menuHandler} />
    </>
  );
};
